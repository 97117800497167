import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import styles from './Categories.module.scss';
import { Link } from 'gatsby';

const HTMLCSSPosts = () => {
    return (
        <StaticQuery query={graphql`
            {
                allWordpressWpHtmlCss{
                edges{
                  node{
                    slug
                    content
                    type
                    title
                    id
                    excerpt
                    acf {
                      seo_desc
                    }
                    featured_media{
                      source_url
                    }
                  }
                }
              }
            }
        `} render={({allWordpressWpHtmlCss}) => (
          <main className={styles.cat__items__container}>
            {allWordpressWpHtmlCss.edges.map((item, i) => (
                <article key={i+3} className={styles.cat__item}>
                    <header className={styles.header}>
                        <Link to={`/${item.node.type}/${item.node.slug}`}><h2 className={styles.headerPost}>{item.node.title}</h2></Link>
                        <Link to={`/${item.node.type}/${item.node.slug}`}><div className={styles.excerpt} dangerouslySetInnerHTML={{__html: item.node.excerpt}} /></Link>
                    </header>
                
                </article>
            )) }
          </main>
        )} />
    )
};

export default HTMLCSSPosts;