import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import HTMLCSSPosts from "../components/Categories/HTMLCSSPosts"
//import AsideRight from '../components/Asides/Aside_right/Aside_right'
import ADS from '../components/Asides/ADS/Ads_left'

const HTMLCSS = () => (
  <Layout>
    <ADS />
    <SEO title='Посты о HTML, CSS и вёрстке' description='Посты блога PageCard о SMM, маркетинге в социальных сетях и многом другом из сферы молодого и технологичного бизнеса' />
    <HTMLCSSPosts />
    
  </Layout>
);

export default HTMLCSS
